import Swiper, { Pagination, Navigation } from 'swiper';
import 'swiper/css/pagination';

export default (listingId, enableDrag) => ({
    init() {
        let slider = document.getElementById('gallery_slider_' + listingId);

        if (slider != null) {
            new Swiper(slider, this.getSliderOptions());
        }
    },

    getSliderOptions() {
        return {
            preloadImages: true,
            loop: false,
            observer: true,
            observeParents: true,
            lazy: true,
            slidesPerView: 1,
            resistance: true,
            resistanceRatio: 0,
            watchOverflow: true,
            grabCursor: true,
            modules: [Pagination, Navigation],
            allowTouchMove: enableDrag,
            pagination: {
                el: '.image-slider-pagination-' + listingId,
                dynamicBullets: true,
                dynamicMainBullets: 3,
            },
            navigation: {
                nextEl: '.next-image-slider-' + listingId,
                prevEl: '.prev-image-slider-' + listingId,
            },
        };
    },
});
